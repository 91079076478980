/*-----------------------------------------------
|   Reboot
-----------------------------------------------*/
::placeholder {
  opacity: 1;
  color: $gray-400 !important;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  position: relative;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161c2d;
  text-align: left;
  background-color: #fff;
  overflow-x: hidden;
}
