.badge {
  line-height: 0.8125rem;
}

.badge-circle {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  padding: 0.5625rem 0.8125rem;
}
