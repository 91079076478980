// MIXED CSS
.z-index-1 {
  z-index: 1;
}

@include media-breakpoint-up(md) {
  .card-form {
    max-width: 24.5rem;
  }
}

.contains-anchor {
  .anchor {
    text-decoration: none;
    margin-left: 0.4rem;
    opacity: 0;
  }
  &:hover {
    .anchor {
      opacity: 1;
    }
  }
}
