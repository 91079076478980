.footer-title {
  font-size: 0.75rem;
  margin-bottom: map_get($spacers, 3);
  text-transform: uppercase;
  font-weight: bold;
}

a.text-500 {
  color: $gray-500 !important;
  &:hover {
    color: $gray-600 !important;
  }
}
